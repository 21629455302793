import { isServer } from "@/constants/common";
import { ServerSidePropsContextParams } from "@/lib/apollo/types";
import { fetchAuthSession as fetchAuthSessionClient } from "aws-amplify/auth";
import { fetchAuthSession } from "aws-amplify/auth/server";
import { runWithAmplifyServerContext } from "@/lib/amplify/pagesRouter";
import { logger } from "./logger";

export const getAccessTokenAmplify = async ({ req, res }: ServerSidePropsContextParams) => {
  try {
    if (!isServer) {
      const session = await fetchAuthSessionClient();
      return session.tokens?.accessToken || null;
    }
    if (!req || !res) {
      const missing = !req ? "request" : "response";
      const urlInfo = req ? `URL: ${req.url}` : "URL information is unavailable";
      throw new Error(`Server-side authentication failed: ${missing} object is missing. ${urlInfo}`);
    }

    const accessToken = await runWithAmplifyServerContext({
      nextServerContext: { request: req, response: res },
      operation: async (contextSpec) => {
        const session = await fetchAuthSession(contextSpec);
        return session.tokens?.accessToken || null;
      },
    });

    return accessToken || null;
  } catch (error) {
    logger(`Error fetching access token: ${error}`);
    return null;
  }
};
