import { BACKEND_API_URL } from "@/constants/common";
import { getAccessToken } from "@/utils/authUtils";
import { logger } from "@/utils/logger";
import { ApolloLink } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { ServerSidePropsContextParams } from "./types";

const APOLLO_BATCH_INTERVAL = 10;

export const createBatchHttpLink = () =>
  new BatchHttpLink({
    uri: `${BACKEND_API_URL}`,
    batchMax: 10,
    /*
    TODO: Allow when we're ready send tokens by cookies
    credentials: "include", */
    batchInterval: APOLLO_BATCH_INTERVAL,
  });

export const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      const errorCode = typeof error.extensions?.code === "string" ? error.extensions.code : undefined;
      logger(new Error(errorCode ?? "UNKNOWN"), "error-middleware", errorCode, {
        operation: operation.operationName,
        variables: operation.variables,
      });
    });
  }

  if (networkError) {
    logger(new Error(`[Network error]: ${networkError}`), "error-middleware", undefined, {
      operation: operation.operationName,
      variables: operation.variables,
    });
  }
});

export const createLanguageMiddleware = (language: string) =>
  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        "x-language": language,
        ...operation.getContext().headers,
      },
    }));

    return forward(operation);
  });

export const createAuthMiddleware = ({ req, res }: ServerSidePropsContextParams) =>
  setContext(async (_, previousContext) => {
    const token = await getAccessToken({ req, res });
    try {
      return {
        headers: {
          ...previousContext.headers,
          Authorization: token ? `Bearer ${token}` : "",
        },
      };
    } catch (error) {
      logger(error);
      return previousContext;
    }
  });
