import { useUserReservationTotalCountQuery } from "@/data-access/query/__generated__/userReservationCount";
import { useSession } from "@/providers/SessionProvider";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { Analytics } from "..";

function useScreenViewAnalytics() {
  const router = useRouter();
  const { user, isLoggedIn } = useSession();

  const { data } = useUserReservationTotalCountQuery({
    skip: !isLoggedIn,
  });

  useEffect(() => {
    const trackScreenView = async () => {
      if (isLoggedIn && user?.id) {
        const totalCount = data?.getUser.reservationsCount ?? 0;

        Analytics.setUserData({
          user: { id: user.id, newCustomer: totalCount < 2 },
        });
      }

      Analytics.sendScreenView({
        event: "screen_view",
        path: `${process.env.NEXT_PUBLIC_BASE_URL}${router.asPath}`,
      });
    };

    trackScreenView();

    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only trigger on route change
  }, [router.asPath]);
}

export default useScreenViewAnalytics;
