import { cn } from "@/utils/cn";
import React from "react";
import { Shell } from "./Shell";
import { Icons } from "./icons";

interface ErrorTemplateProps {
  className?: string;
  icon?: keyof typeof Icons;
  title: string;
  children?: React.ReactNode;
}

const ErrorTemplate = ({ icon = "circle-help", title, className, children }: ErrorTemplateProps) => {
  const Icon = Icons[icon];

  return (
    <Shell variant="content" className={cn("flex h-dvh flex-col items-center gap-y-2 pt-16 md:max-w-md", className)}>
      <Icon className="size-32 rounded-full bg-box p-4 text-primary-dark" />
      <h1 className="h3 text-center">{title}</h1>
      {children}
    </Shell>
  );
};

export default ErrorTemplate;
