export const FORM_FIELDS = {
  FIRST_NAME: "firstname",
  SURNAME: "surname",
  NATIONALITY: "nationality",
  ID_CARD_TYPE: "idCardType",
  ID_CARD: "idCard",
  VISA: "visa",
  DATE: "date",
  ADDRESS: "address",
  COUNTRY: "country",
  STREET: "street",
  CITY: "city",
  ZIP: "zip",
  GSM: "gsm",
  EMAIL: "email",
  CAR_LICENSE_PLATE: "carLicensePlate",
  SIGNATURE: "signature",
  ID_STAY_REASON: "idStayReason",
  GDPR_MARKETING: "gdprMarketing",
  ACCOMMODATION_TERMS: "accommodationTerms",
  RESERVATION_NUMBER: "reservationNumber",
  OWNER_LAST_NAME: "ownerLastName",
  ACCOUNT_CREATION_CONSENT: "accountCreationConsent",
  MESSAGE: "message",
  DATE_OF_BIRTH: "dateOfBirth",
  RESERVATION_ID: "reservationId",
  GUEST_ID: "guestId",
  TOKEN: "token",
  GDPR_REPETITIVE_STAY: "gdprRepetitiveStay",
  REQUESTED_DATE: "requestedDate",
  SEND_OPTION: "sendOption",
  LANGUAGE: "language",
  VAT_ID: "vatId",
  BUSINESS_NAME: "businessName",
  BUSINESS_ID: "businessId",
  INCLUDE_BILLING_ADDRESS: "includeBillingAddress",
  BILLING_INFORMATION: "billingInformation",
  PAYMENT_METHOD: "paymentMethod",
  ACCOUNT_IDS: "accountIds",
  IS_MAIN_GUEST: "isMainGuest",
  TIP_AMOUNT: "tipAmount",
} as const;
