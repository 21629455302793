import { isServer } from "@/constants/common";
import { DEFAULT_LOCALE } from "@/constants/i18n";
import { ApolloClient, InMemoryCache, NormalizedCacheObject, from } from "@apollo/client";
import merge from "deepmerge";
import { GetServerSidePropsContext } from "next";
import equal from "fast-deep-equal";
import { createAuthMiddleware, createBatchHttpLink, createLanguageMiddleware, errorLink } from "./middlewares";
import { ServerSidePropsContextParams } from "./types";

interface CreateApolloClientParams extends ServerSidePropsContextParams {
  locale: string;
}
const createApolloClient = ({ locale, req, res }: CreateApolloClientParams) => {
  const languageLink = createLanguageMiddleware(locale);
  const batchHttpLink = createBatchHttpLink();
  const authLink = createAuthMiddleware({ req, res });

  return new ApolloClient({
    ssrMode: isServer,
    link: from([languageLink, authLink, errorLink, batchHttpLink]),
    connectToDevTools: process.env.NODE_ENV === "development",
    cache: new InMemoryCache({
      typePolicies: {
        ReservationDetail: {
          keyFields: ["reservationId"],
        },
      },
    }),
  });
};

interface InitializeApolloType extends ServerSidePropsContextParams {
  initialState?: NormalizedCacheObject;
  locale?: string;
  query?: GetServerSidePropsContext["query"];
}

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";
let apolloClientInstance: ApolloClient<NormalizedCacheObject>;

/**
 * Initializes the Apollo client for both server-side and client-side usage.
 * @note This function should not be used directly within getServerSideProps in Next.js.
 * Instead, use a server-specific initialization method `initializeServerSideApolloClient()` if you're working in a
 * server-side context, to ensure all required parameters (e.g., req and res) are included.
 *
 * @see initializeServerSideApolloClient() for server-side initialization.
 */

export const initializeApollo = ({ initialState, locale, req, res }: InitializeApolloType) => {
  const language = locale ?? DEFAULT_LOCALE;

  const currentApolloClient = apolloClientInstance ?? createApolloClient({ locale: language, req, res });

  if (initialState) {
    const existingCache = currentApolloClient.extract();

    const data = merge(existingCache, initialState, {
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((dest) => sourceArray.every((src) => !equal(dest, src))),
      ],
    });

    currentApolloClient.cache.restore(data);
  }

  if (isServer) return currentApolloClient;

  if (!apolloClientInstance) apolloClientInstance = currentApolloClient;

  return currentApolloClient;
};

interface InitializeServerSideApolloClientType extends ServerSidePropsContextParams {
  initialState?: NormalizedCacheObject;
  locale?: string;
  query?: GetServerSidePropsContext["query"];
}

export const initializeServerSideApolloClient = (params: InitializeServerSideApolloClientType) => initializeApollo(params);
