import { DATE_LOCALE_TO_OBJECT_MAP, DEFAULT_LOCALE } from "@/constants/i18n";
import { format, formatISO, getYear, isValid } from "date-fns";
import { z } from "zod";
import { logger } from "./logger";

export interface DateRange {
  from?: Date | null;
  to?: Date | null;
}

export interface DateObject {
  day: string;
  month: string;
  year: string;
}

export const DATE_FORMATS = {
  DATE_DEFAULT: "dd LLL",
  DATE_ISO: "yyyy-MM-dd",
  DATE_SHORT: "d. L.",
  DATE_LONG: "d. L. yyyy",
  MONTH_LONG: "LLLL",
};

export const getDateFnsLocaleFromLocaleString = (locale: string) => {
  if (locale in DATE_LOCALE_TO_OBJECT_MAP) {
    return DATE_LOCALE_TO_OBJECT_MAP[locale as keyof typeof DATE_LOCALE_TO_OBJECT_MAP];
  }

  return DATE_LOCALE_TO_OBJECT_MAP[DEFAULT_LOCALE];
};

export const formatSimpleDateRange = (
  date?: DateRange,
  formatPatternFrom = DATE_FORMATS.DATE_DEFAULT,
  formatPatternTo = DATE_FORMATS.DATE_DEFAULT,
): string | null => {
  if (date?.from) {
    if (date.to) {
      return `${format(date.from, formatPatternFrom)} - ${format(date.to, formatPatternTo)}`;
    }
    return format(date.from, formatPatternFrom);
  }
  return null;
};

export const formatDate = (date?: Date | number, formatPattern = DATE_FORMATS.DATE_DEFAULT): string | null =>
  date ? format(date, formatPattern) : null;

export const formatDateISO = (date?: Date | number): string | undefined => (date ? format(date, DATE_FORMATS.DATE_ISO) : undefined);

export function formatDateRange(dateRange: DateRange, formatStr: string = DATE_FORMATS.DATE_DEFAULT): string | null {
  if (!dateRange.from) return null;

  const fromYear = getYear(dateRange.from);
  const toYear = dateRange.to ? getYear(dateRange.to) : null;

  if (!dateRange.to) {
    return format(dateRange.from, formatStr);
  }

  if (fromYear === toYear) {
    const withoutYearFormat = formatStr.replace(".yyyy", "");
    return `${format(dateRange.from, withoutYearFormat)} - ${format(dateRange.to, formatStr)}`;
  }
  return `${format(dateRange.from, formatStr)} - ${format(dateRange.to, formatStr)}`;
}

export const isValidDate = (date: any): boolean => {
  if (!date) {
    return false;
  }

  if (date instanceof Date) {
    return isValid(date);
  }

  if (typeof date === "string" || typeof date === "number") {
    const parsedDate = new Date(date);
    return isValid(parsedDate);
  }

  return false;
};

export const validateAndParseDate = (dateString: string | undefined | null, ignoreError?: boolean) => {
  if (!dateString) {
    return undefined;
  }

  const toDate = z.coerce.date();

  const validationResult = toDate.safeParse(dateString);
  if (!validationResult.success) {
    if (!ignoreError) {
      logger(validationResult.error);
    }
    return undefined;
  }

  const day = validationResult.data.getDate().toString();
  const month = (validationResult.data.getMonth() + 1).toString();
  const year = validationResult.data.getFullYear().toString();

  return { day, month, year };
};

export const dateObjectToISO = (date: { day: string; month: string; year: string }): string => {
  const { day, month, year } = date;
  const dateObj = new Date(`${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`);
  return formatISO(dateObj, { representation: "date" });
};

/**
 * Parse YYMMDD to date object for guest form
 * @param idDateString
 * @returns DateObject | null
 */
export const idDateToDateObject = (idDateString?: string) => {
  const year = idDateString?.slice(0, 2);
  const month = idDateString?.slice(2, 4);
  const day = idDateString?.slice(4, 6);

  if (year === undefined || month === undefined || day === undefined) {
    return null;
  }

  const date = new Date(`${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`);

  if (!date) return null;

  return {
    day: date.getDate().toString(),
    month: (date.getMonth() + 1).toString(),
    year: date.getFullYear().toString(),
  };
};
