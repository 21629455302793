import { CalendarCheck, CircleHelp, CircleUser, Hotel, MessageSquare, ReceiptTextIcon, Search, Settings, TrafficCone, User } from "lucide-react";

export type IconProps = React.HTMLAttributes<SVGElement>;

export const Icons = {
  logo: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40" {...props}>
      <path
        fill="#525E77"
        d="M20 0C8.972 0 0 8.972 0 20c0 11.029 8.972 20 20 20 11.029 0 20-8.971 20-20C40 8.972 31.029 0 20 0Zm0 38.791C9.639 38.791 1.209 30.361 1.209 20S9.639 1.209 20 1.209 38.791 9.639 38.791 20 30.361 38.791 20 38.791Z"
      />
      <path
        fill="#525E77"
        d="M29.916 13.438c1.265-.302 2.172-1.15 2.172-2.719 0-1.999-1.524-2.834-3.322-2.834h-3.624v4.559h-9.36c-.191-2.948-2.144-4.703-4.398-4.703-2.374 0-4.416 1.942-4.416 5.178 0 3.235 2.042 5.178 4.416 5.178 2.21 0 4.132-1.685 4.386-4.53h9.373v4.387h1.28v-4.388h1.136c1.194 0 1.755.69 2.503 1.956l1.41 2.43h1.48l-1.495-2.59c-.475-.82-.935-1.48-1.54-1.927m-18.533 3.513c-1.582 0-3.065-1.338-3.065-4.028 0-2.689 1.481-4.042 3.065-4.042 1.584 0 3.064 1.351 3.064 4.042 0 2.692-1.48 4.028-3.064 4.028Zm17.354-4.502h-2.316V9.007h2.316c1.122 0 2.028.503 2.028 1.712s-.878 1.727-2.028 1.727Zm-.965 8.998-1.707 4.559H9.981v-3.438h5.25v-1.123H8.698v10.07h6.531v-1.123H9.981v-3.265h15.664l-1.642 4.387h1.295l1.607-4.387h3.08l1.598 4.387h1.366l-3.768-10.069h-1.41l.002.002Zm-.457 4.559 1.133-3.093 1.127 3.093h-2.26Z"
      />
    </svg>
  ),
  calendar: CalendarCheck,
  chat: MessageSquare,
  profile: CircleUser,
  user: User,
  settings: Settings,
  hotel: Hotel,
  "circle-help": CircleHelp,
  search: Search,
  traffic: TrafficCone,
  receipt: ReceiptTextIcon,
  enterTablet: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 200 201" {...props}>
      <g fill="#525E77" clipPath="url(#a)">
        <path d="M118.201 163.784c-5.005 0-9.099-4.094-9.099-9.099a9.067 9.067 0 0 1 9.099-9.099c5.004 0 9.099 4.095 9.099 9.099 0 5.005-4.095 9.099-9.099 9.099Zm-27.937-55.23a9.1 9.1 0 0 0 0-6.915 9.139 9.139 0 0 0-2.002-3.003L51.865 62.24a9.062 9.062 0 0 0-12.83 0 9.062 9.062 0 0 0 0 12.83l20.838 20.837H9.099C4.095 95.907 0 100 0 105.006c0 5.004 4.095 9.099 9.1 9.099h50.864l-20.837 20.837a9.062 9.062 0 0 0 0 12.83c1.82 1.82 4.094 2.639 6.46 2.639 2.366 0 4.64-.91 6.46-2.639l36.397-36.397c.819-.819 1.547-1.82 2.002-3.003l-.182.182Z" />
        <path d="M172.699 0H63.509C48.403 0 36.21 12.193 36.21 27.297v11.101c0 5.005 4.095 9.1 9.1 9.1 5.004 0 9.098-4.095 9.098-9.1v-11.1c0-5.005 4.095-9.1 9.1-9.1h109.19c5.004 0 9.099 4.095 9.099 9.1v145.586c0 5.005-4.095 9.1-9.099 9.1H63.509c-5.005 0-9.1-4.095-9.1-9.1a7.34 7.34 0 0 0-7.37-7.37h-3.458a7.34 7.34 0 0 0-7.37 7.37c0 15.105 12.193 27.298 27.297 27.298H172.7c15.104 0 27.297-12.193 27.297-27.298V27.297C199.996 12.193 187.803 0 172.699 0Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h200v200.182H0z" />
        </clipPath>
      </defs>
    </svg>
  ),
  idScan: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 207 138" {...props}>
      <rect width="178" height="107" x="14.5" y="16" fill="#F9F9F9" rx="8" />
      <path
        fill="#525E77"
        fillRule="evenodd"
        d="M50.664 53.333c0-8.744 7.089-15.833 15.833-15.833 8.745 0 15.834 7.089 15.834 15.833 0 8.666-6.962 15.706-15.597 15.832a37.257 37.257 0 0 0-.473 0c-8.635-.126-15.597-7.166-15.597-15.832Zm4.93 17.756c-5.958-3.666-9.93-10.247-9.93-17.756 0-11.506 9.328-20.833 20.833-20.833 11.506 0 20.834 9.327 20.834 20.833 0 7.51-3.973 14.09-9.93 17.756a31.83 31.83 0 0 1 20.93 29.908 2.5 2.5 0 1 1-5 0 26.833 26.833 0 0 0-53.667 0 2.5 2.5 0 1 1-5 0 31.833 31.833 0 0 1 20.93-29.908ZM109 53a2.5 2.5 0 0 1 2.5-2.5h60a2.5 2.5 0 1 1 0 5h-60A2.5 2.5 0 0 1 109 53Zm0 16a2.5 2.5 0 0 1 2.5-2.5h60a2.5 2.5 0 1 1 0 5h-60A2.5 2.5 0 0 1 109 69Zm2.5 13.5a2.5 2.5 0 1 0 0 5h40a2.5 2.5 0 1 0 0-5h-40Z"
        clipRule="evenodd"
      />
      <path
        stroke="#525E77"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
        d="M2.5 24V13.5A10.5 10.5 0 0 1 13 3h10.5m160 0H194a10.502 10.502 0 0 1 10.5 10.5V24m0 90v10.5A10.502 10.502 0 0 1 194 135h-10.5m-160 0H13a10.5 10.5 0 0 1-10.5-10.5V114"
      />
    </svg>
  ),
  idScanBack: (props: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 207 138" {...props}>
      <rect width="178" height="107" x="14.5" y="16" fill="#F9F9F9" rx="8" />
      <path
        fill="#525E77"
        fillRule="evenodd"
        d="M38.5 42.5a2.5 2.5 0 0 0 0 5h130a2.5 2.5 0 1 0 0-5h-130Zm0 16a2.5 2.5 0 0 0 0 5h130a2.5 2.5 0 1 0 0-5h-130ZM36 77a2.5 2.5 0 0 1 2.5-2.5h130a2.5 2.5 0 1 1 0 5h-130A2.5 2.5 0 0 1 36 77Zm2.5 13.5a2.5 2.5 0 0 0 0 5h87a2.5 2.5 0 1 0 0-5h-87Z"
        clipRule="evenodd"
      />
      <path
        stroke="#525E77"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
        d="M2.5 24V13.5A10.5 10.5 0 0 1 13 3h10.5m160 0H194a10.502 10.502 0 0 1 10.5 10.5V24m0 90v10.5A10.502 10.502 0 0 1 194 135h-10.5m-160 0H13a10.5 10.5 0 0 1-10.5-10.5V114"
      />
    </svg>
  ),
};
