import { isProduction } from "@/constants/common";
import { AuthTokens } from "@/data-access/gqlTypes";
import { AuthCookiesSchema } from "@/lib/validations/auth";
import type { JwtPayload } from "jwt-decode";
import { jwtDecode } from "jwt-decode";
import { NextResponse } from "next/server";
import { AUTH_COOKIES, Role } from "./authUtils";
import { extractAndConvertCookiesFromHeaders } from "./cookieUtils";
import { logger } from "./logger";

const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000;

export const calculateTokenMaxAge = async (token: string) => {
  try {
    const decodedToken = jwtDecode<JwtPayload>(token);
    if (typeof decodedToken.exp === "undefined") {
      throw new Error("Expiration time is missing from the token");
    }

    const expirationTime = new Date(decodedToken.exp * 1000);
    const maxAge = (expirationTime.getTime() - new Date().getTime()) / 1000;

    return maxAge;
  } catch (error) {
    logger(error);
    throw new Error("Invalid token");
  }
};

interface ValidateTokenResult {
  isValid: boolean;
  isExpired?: boolean;
  roles: Role[];
  user?: {
    firstName?: string;
    lastName?: string;
    id?: string;
  };
}

export const validateToken = async (accessToken: string): Promise<ValidateTokenResult> => {
  try {
    const decodedToken = jwtDecode<DecodedJwt>(accessToken);
    const currentTime = Math.floor(Date.now() / 1000);

    if (typeof decodedToken.exp === "undefined") {
      throw new Error("Expiration time is missing from the token");
    }

    const expTime = decodedToken.exp;

    if (expTime < currentTime) {
      return { isValid: false, isExpired: true, roles: decodedToken["cognito:groups"] || [] };
    }
    return {
      isValid: true,
      roles: decodedToken["cognito:groups"] || [],
      user: {
        firstName: decodedToken.name,
        lastName: decodedToken.family_name,
        id: decodedToken["cognito:username"],
      },
    };
  } catch (error) {
    logger(error);
    return { isValid: false, roles: [] };
  }
};

interface CookieSettingParams {
  res: NextResponse;
  authTokens: AuthTokens;
  reservationId?: number;
}

export const setAuthAndReservationCookies = async ({ res, authTokens, reservationId }: CookieSettingParams) => {
  const { accessToken, refreshToken, idToken } = authTokens;

  const { isValid, roles } = await validateToken(accessToken);

  const isUser = isValid && roles.includes("user");

  if (!isUser && reservationId) {
    res.cookies.set({
      name: AUTH_COOKIES.RESERVATION_ID,
      value: String(reservationId),
      path: "/",
      sameSite: "lax",
      secure: true,
      maxAge: oneYearInMilliseconds,
    });
  }

  const accessTokenMaxAge = await calculateTokenMaxAge(accessToken);
  res.cookies.set({
    name: AUTH_COOKIES.ACCESS_TOKEN,
    value: accessToken,
    path: "/",
    sameSite: "lax",
    secure: true,
    maxAge: accessTokenMaxAge,
  });

  if (idToken) {
    const idTokenMaxAge = await calculateTokenMaxAge(idToken);
    res.cookies.set({
      name: AUTH_COOKIES.ID_TOKEN,
      value: idToken,
      path: "/",
      sameSite: "lax",
      secure: true,
      maxAge: idTokenMaxAge,
      domain: isProduction ? ".orea.cz" : undefined,
    });
  } else {
    res.cookies.set({
      name: AUTH_COOKIES.ID_TOKEN,
      value: "",
      path: "/",
      sameSite: "lax",
      secure: true,
      maxAge: 0, // immediately expire the cookie
    });
  }

  res.cookies.set({
    name: AUTH_COOKIES.REFRESH_TOKEN,
    value: refreshToken,
    httpOnly: true,
    path: "/",
    sameSite: "lax",
    secure: true,
    maxAge: oneYearInMilliseconds,
  });
};

export const resetAuthCookies = (res: NextResponse) => {
  const cookieOptions = {
    path: "/",
    sameSite: "lax",
    secure: true,
    maxAge: 0, // immediately expire the cookie
  } as const;

  res.cookies.set({
    name: AUTH_COOKIES.ACCESS_TOKEN,
    value: "",
    ...cookieOptions,
  });

  res.cookies.set({
    name: AUTH_COOKIES.ID_TOKEN,
    value: "",
    ...cookieOptions,
  });

  res.cookies.set({
    name: AUTH_COOKIES.RESERVATION_ID,
    value: "",
    ...cookieOptions,
  });

  res.cookies.set({
    name: AUTH_COOKIES.REFRESH_TOKEN,
    value: "",
    ...cookieOptions,
    httpOnly: true,
  });
};

interface DecodedJwt {
  exp?: number;
  "cognito:groups"?: Role[];
  name?: string;
  family_name?: string;
  "cognito:username"?: string;
}

export const getAuthTokensFromResponse = (headers: any) => {
  const cookies = extractAndConvertCookiesFromHeaders(headers);

  const result = AuthCookiesSchema.safeParse(cookies);
  if (result.success) {
    return result.data;
  }

  return null;
};

export const isUserAuthenticated = async (accessToken?: string) => {
  if (!accessToken) {
    return false;
  }

  const { isValid } = await validateToken(accessToken);

  return isValid;
};
