import { CheckinStepEvent, FormSentAppEvent, GuestFilledEvent, ReservationViewEvent, ScreenViewEvent, UserData } from "./types";
import { sendEvent } from "./utils";

export const Analytics = {
  setUserData: (data: UserData) => sendEvent(data),
  sendScreenView: (data: ScreenViewEvent) => sendEvent(data),
  sendCheckinStep: (data: CheckinStepEvent) => sendEvent(data),
  sendServiceForm: (data: FormSentAppEvent) => sendEvent(data),
  sendGuestFilled: (data: GuestFilledEvent) => sendEvent(data),
  sendReservationViewEvent: (data: ReservationViewEvent) => sendEvent(data),
};
