import { ApolloError, isApolloError } from "@apollo/client";
import { isAxiosError } from "axios";
import { logger } from "./logger";

interface GraphQLError {
  extensions: {
    code: string;
  };
}

export const isGraphQLError = (error: unknown): error is GraphQLError => (error as GraphQLError).extensions !== undefined;

export const getApolloErrorCodes = (error: unknown) => {
  if (error instanceof Error && isApolloError(error)) {
    return error.graphQLErrors.map((graphQLError) => graphQLError.extensions?.code).filter((code): code is string => typeof code === "string");
  }
  return [];
};

export const getApolloErrorCodesOrFallback = (errors: unknown, defaultCode: string = "UNKNOWN"): string[] => {
  const errorCodes = getApolloErrorCodes(errors);
  return errorCodes.length > 0 ? errorCodes : [defaultCode];
};

export const getGraphQLErrorCodes = (error: unknown): string[] => {
  if (Array.isArray(error) && error.every(isGraphQLError)) {
    return error.map((graphQLError) => graphQLError.extensions?.code).filter((code): code is string => typeof code === "string");
  }

  if (isGraphQLError(error)) {
    return [error.extensions.code].filter((code): code is string => typeof code === "string");
  }

  return [];
};

export const getGraphQLErrorCodesOrFallback = (errors: unknown, defaultCode: string = "UNKNOWN"): string[] => {
  const errorCodes = getGraphQLErrorCodes(errors);
  return errorCodes.length > 0 ? errorCodes : [defaultCode];
};

export const getAxiosErrorMessage = (error: unknown) => {
  if (isAxiosError(error)) {
    return error.response?.data.error;
  }

  return undefined;
};

export const logApolloErrors = (...errors: (ApolloError | undefined)[]) => {
  errors.forEach((error) => {
    if (error) {
      logger(error);
    }
  });
};
