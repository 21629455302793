import { CHECKOUT_ERRORS, LOGIN_SERVICE_ERRORS, RESERVATION_DETAIL_ERRORS } from "@/constants/errors";
import { ApolloError, isApolloError } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";
import { isProductionBuild } from "@/constants/common";
import { getApolloErrorCodes } from "./errorUtils";

export const ignoredErrorCodesForSentry: Set<string> = new Set([
  ...Object.values(RESERVATION_DETAIL_ERRORS),
  ...Object.values(LOGIN_SERVICE_ERRORS),
  ...Object.values(CHECKOUT_ERRORS),
]);

const formatError = (error: any): Error => {
  if (Array.isArray(error)) {
    const formattedMessages = error
      .map((err) => {
        if (err.message) {
          return `${err.message}`;
        }
        return formatError(err).message;
      })
      .join("; ");
    return new Error(formattedMessages);
  }

  if (error instanceof Error && !isApolloError(error)) {
    return error;
  }

  if (error instanceof ApolloError) {
    const errorMessage = error.message;

    const formattedMessage = `ApolloError: ${errorMessage}`;
    return new Error(formattedMessage);
  }
  if (typeof window !== "undefined" && error instanceof ErrorEvent && error.message) {
    return new Error(`ErrorEvent: ${error.message}`);
  }
  if (typeof error === "string" || error instanceof String) {
    return new Error(`StringError: ${error}`);
  }
  return new Error(`Unknown error type: ${JSON.stringify(error)}`);
};

type LoggerContext = "gSSP" | "error-middleware" | "api-endpoint";

export const logger = (error: any, context?: LoggerContext, errorCode?: string, payload?: Record<string, any>) => {
  const errorCodes = getApolloErrorCodes(error);
  const formattedError = formatError(error);

  if (errorCode) {
    errorCodes.push(errorCode);
  }
  const shouldLogToSentry = !errorCodes.length || errorCodes.some((code) => !ignoredErrorCodesForSentry.has(code));

  if (isProductionBuild) {
    if (shouldLogToSentry) {
      Sentry.captureException(formattedError, {
        tags: { context: context || "general" },
        extra: { originalError: error, errorCodes, payload },
        fingerprint: [errorCode ?? "UNKNOWN"],
      });
    }
  } else {
    // eslint-disable-next-line no-console
    console.error(`Logged Error${!shouldLogToSentry ? "(Ignored in Sentry)" : ""}: ${formattedError.message}`);
  }
};
