import { Role, Session, USER_ROLES, User } from "@/utils/authUtils";
import { ReactNode, createContext, useCallback, useContext, useMemo, useState } from "react";

interface LoginParams {
  roles: Role[];
  user: User;
}

interface SessionContextType extends Pick<Session, "roles" | "user"> {
  isAuthenticated: boolean;
  isLoggedIn: boolean;
  logout: () => void;
  login: (params: LoginParams) => void;
}

const defaultSessionContext: SessionContextType = {
  isAuthenticated: false,
  isLoggedIn: false,
  roles: [],
  logout: () => {},
  user: null,
  login: () => {},
};

const SessionContext = createContext<SessionContextType>(defaultSessionContext);

interface SessionProviderProps {
  children: ReactNode;
  initialSessionState?: Session;
}

export const SessionProvider = ({ children, initialSessionState }: SessionProviderProps) => {
  const { isValid = false, roles = [], user = null } = initialSessionState || {};

  const [session, setSession] = useState<Pick<SessionContextType, "isAuthenticated" | "isLoggedIn" | "roles" | "user">>({
    isAuthenticated: isValid,
    isLoggedIn: isValid && roles.includes(USER_ROLES.USER),
    roles,
    user,
  });

  const logout = useCallback(() => {
    setSession({ isAuthenticated: false, isLoggedIn: false, roles: [], user: null });
  }, [setSession]);

  const login = useCallback(
    ({ roles, user }: LoginParams) => {
      setSession({
        isAuthenticated: true,
        isLoggedIn: roles.includes(USER_ROLES.USER),
        roles,
        user,
      });
    },
    [setSession],
  );

  const contextValue = useMemo(
    () => ({
      ...session,
      logout,
      login,
    }),
    [session, logout, login],
  );
  return <SessionContext.Provider value={contextValue}>{children}</SessionContext.Provider>;
};

export const useSession = () => {
  const context = useContext(SessionContext);

  if (!context) {
    throw new Error("SessionContext is not initialized");
  }

  return context;
};
