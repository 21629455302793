export const parseCookies = (cookieHeader: string | undefined) => {
  const cookies: Record<string, string> = {};
  if (cookieHeader) {
    cookieHeader.split(";").forEach((cookie) => {
      const parts = cookie.split("=").map((c) => c.trim());
      const name = parts[0];
      const value = parts[1];

      if (name && value !== undefined) {
        cookies[name] = decodeURIComponent(value);
      }
    });
  }
  return cookies;
};

export const extractAndConvertCookiesFromHeaders = (headers: any): Record<string, string> => {
  const cookiesObject: Record<string, string> = {};
  const setCookies = headers["set-cookie"];
  if (setCookies && Array.isArray(setCookies)) {
    setCookies.forEach((cookie) => {
      const keyValueSegment = cookie.split(";")[0];
      const [key, value] = keyValueSegment.split("=");
      if (key && value) {
        cookiesObject[key] = decodeURIComponent(value.split(";")[0]);
      }
    });
  }
  return cookiesObject;
};
