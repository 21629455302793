import { ApolloClient, InMemoryCache, NormalizedCacheObject, from } from "@apollo/client";
import { NextRequest, NextResponse } from "next/server";
import { APOLLO_STATE_PROP_NAME } from ".";
import { createBatchHttpLink, createLanguageMiddleware, errorLink } from "./middlewares";

export const addApolloState = <T extends { props?: Record<string, any> }>(client: ApolloClient<NormalizedCacheObject>, pageProps: T) => {
  const apolloState = client.cache.extract();

  return {
    ...pageProps,
    props: {
      ...(pageProps.props || {}),
      [APOLLO_STATE_PROP_NAME]: apolloState,
    },
  };
};
interface CreateMiddlewareApolloClientParams {
  locale: string;
  req: NextRequest;
  res: NextResponse;
}

export const createMiddlewareApolloClient = ({ locale }: CreateMiddlewareApolloClientParams) => {
  const languageLink = createLanguageMiddleware(locale);
  const batchHttpLink = createBatchHttpLink();

  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: from([errorLink, languageLink, batchHttpLink]),
    connectToDevTools: process.env.NODE_ENV === "development",
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            verifyPayment: {
              keyArgs: false,
            },
          },
        },
      },
    }),
  });
};
