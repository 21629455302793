import { FORM_FIELDS } from "@/constants/formFields";
import { z } from "zod";

export const loginSchema = z.object({
  [FORM_FIELDS.EMAIL]: z.string().email(),
});

export type LoginInputs = z.infer<typeof loginSchema>;

export const reservationLoginSchema = z.object({
  [FORM_FIELDS.RESERVATION_NUMBER]: z.string().refine((val) => val !== "", {
    params: { i18n: "customErrors.required" },
  }),
  [FORM_FIELDS.OWNER_LAST_NAME]: z.string().refine((val) => val !== "", {
    params: { i18n: "customErrors.required" },
  }),
});

export type ReservationLoginInputs = z.infer<typeof reservationLoginSchema>;

export const AuthCookiesSchema = z.object({
  accessToken: z.string().optional(),
  idToken: z.string().optional(),
  refreshToken: z.string().optional(),
});
