/* eslint-disable max-classes-per-file */
import { BaseError } from "@qest/error-utils";

export const RESERVATION_DETAIL_ERRORS = {
  ACCESS_DENIED: "ACCESS_DENIED",
  ACCESS_DENIED_OR_GUEST_DOES_NOT_EXIST: "ACCESS_DENIED_OR_GUEST_DOES_NOT_EXIST",
  CANCELLED_RESERVATION: "CANCELLED_RESERVATION",
  RESERVATION_DOES_NOT_EXIST: "RESERVATION_DOES_NOT_EXIST",
  ROOMS_ARE_NOT_IN_RESERVATION: "ROOMS_ARE_NOT_IN_RESERVATION",
  INVALID_DATA: "INVALID_DATA",
  ACCESS_DENIED_AC_DOESNT_EXIST: "ACCESS_DENIED_AC_DOESNT_EXIST",
  ACCESS_DENIED_AC_NOT_VALID: "ACCESS_DENIED_AC_NOT_VALID",
  CORRUPT_ACCESS_CODE: "CORRUPT_ACCESS_CODE",
} as const;

export const LOGIN_SERVICE_ERRORS = {
  USER_DOES_NOT_EXIST: "USER_DOES_NOT_EXIST",
  LOGIN_DOES_NOT_EXIST: "LOGIN_DOES_NOT_EXIST",
} as const;

export const LOGIN_RESERVATION_ERRORS = {
  INVALID_SURNAME: "INVALID_SURNAME",
  ACCESS_DENIED: "ACCESS_DENIED",
} as const;

export const SOCIAL_LOGIN_ERRORS = {
  LOGIN_DOES_NOT_EXIST: "LOGIN_DOES_NOT_EXIST",
  UNLOGGED_USER: "UNLOGGED_USER",
  METHOD_NOT_ALLOWED: "METHOD_NOT_ALLOWED",
  INTERNAL_SERVER_ERROR: "INTERNAL_SERVER_ERROR",
  USER_CREATED: "USER_CREATED",
} as const;

export const CHECKOUT_ERRORS = {
  CHECKED_OUT_ALREADY: "CHECKED_OUT_ALREADY",
  EARLY_CHECKOUT: "EARLY_CHECKOUT",
  UNPAID_MAIN_ACCOUNT: "UNPAID_MAIN_ACCOUNT",
  UNPAID_GUEST_ACCOUNT: "UNPAID_GUEST_ACCOUNT",
  ROOM_IN_INVALID_STATE: "ROOM_IN_INVALID_STATE",
  UNKNOWN: "UNKNOWN",
} as const;

export const DEVICES_ERRORS = {
  HMAC_ROOM_ACCESS_NOT_SUPPORTED: "HMAC_ROOM_ACCESS_NOT_SUPPORTED",
  HMAC_VALIDATION_FAILED: "HMAC_VALIDATION_FAILED",
  RESERVATION_NOT_FOUND: "RESERVATION_NOT_FOUND",
} as const;

export const ERROR_CODES = {
  ...RESERVATION_DETAIL_ERRORS,
  ...LOGIN_SERVICE_ERRORS,
  ...LOGIN_RESERVATION_ERRORS,
  ...SOCIAL_LOGIN_ERRORS,
  ...CHECKOUT_ERRORS,
} as const;

export const PAYMENT_ERRORS = {
  PAYMENT_REQUEST_ERROR: "PAYMENT_REQUEST_ERROR",
  PAYMENT_NO_DATA: "PAYMENT_NO_DATA",
  ACCOUNT_PAYED_IN_OTHER_PAYMENT: "ACCOUNT_PAYED_IN_OTHER_PAYMENT",
} as const;

export class PaymentError extends BaseError<keyof typeof PAYMENT_ERRORS> {}

export const REPAYMENT_ERRORS = {
  REPAYMENT_REQUEST_ERROR: "REPAYMENT_REQUEST_ERROR",
} as const;

export class RepaymentError extends BaseError<keyof typeof REPAYMENT_ERRORS> {}

export const CONFIG_ERRORS = {
  CONCRETE_CONFIG_NOT_FOUND: "CONCRETE_CONFIG_NOT_FOUND",
  SCHEMA_NOT_DEFINED: "SCHEMA_NOT_DEFINED",
} as const;

export class ExternalConfigError extends BaseError<keyof typeof CONFIG_ERRORS> {}

export const ARES_ERRORS = {
  DATA_NOT_FOUND: "DATA_NOT_FOUND",
} as const;

export class AresError extends BaseError<keyof typeof ARES_ERRORS> {}
