import { cs, enUS, de } from "date-fns/locale";
import csPhoneInput from "react-phone-number-input/locale/cz";
import enPhoneInput from "react-phone-number-input/locale/en";
import dePhoneInput from "react-phone-number-input/locale/de";
import { Language } from "@/data-access/gqlTypes";

export const DEFAULT_LOCALE = "cs";
export const COOKIE_LOCALE_NAME = "NEXT_LOCALE";

export const DEFAULT_LOCALE_OBJECT: Language = {
  id: DEFAULT_LOCALE,
  icon: undefined,
  localizedName: "Česky",
  name: "Čestina",
  feUsable: true,
};

export const DATE_LOCALE_TO_OBJECT_MAP = {
  cs,
  en: enUS,
  de,
};

export const PHONE_INPUT_LOCALE_TO_OBJECT_MAP = {
  cs: csPhoneInput,
  en: enPhoneInput,
  de: dePhoneInput,
};

export const I18N_NAMESPACES = {
  COMMON: "common",
  PRECHECKIN: "precheckin",
  GUEST: "guest",
  ZOD: "zod",
  THANK_YOU: "thankYou",
  INPUTS: "inputs",
  AUTH: "auth",
  ACCOUNT: "account",
  FAQ: "faq",
  RESERVATIONS: "reservations",
  TABLET_SYSTEM: "tablet-system",
  CHECKOUT: "checkout",
  PAYMENT: "payment",
  ERRORS: "errors",
} as const;

type NamespaceKeys = keyof typeof I18N_NAMESPACES;
export type I18Namespace = (typeof I18N_NAMESPACES)[NamespaceKeys];

export const I18_COMMON_NAMESPACES = [I18N_NAMESPACES.COMMON, I18N_NAMESPACES.ZOD, I18N_NAMESPACES.INPUTS];
