import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/utils/cn";

const shellVariants = cva("px-4 mx-auto", {
  variants: {
    variant: {
      default: "max-w-primary",
      secondary: "max-w-secondary",
      centered: "flex h-screen-dvh max-w-primary flex-col justify-center",
      content: "max-w-5xl",
      container: "max-w-6xl",
      "input-container": "px-0 mx-0 relative w-full space-y-1.5 pt-1 text-primary-dark focus-within:text-input-focus",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

interface ShellProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof shellVariants> {
  as?: React.ElementType;
}

const Shell = ({ className, as: Comp = "div", variant, ...props }: ShellProps) => (
  <Comp className={cn(shellVariants({ variant }), className)} {...props} />
);

export { Shell, shellVariants };
