// eslint-disable @typescript-eslint/no-require-imports
const intervalPlural = require("i18next-intervalplural-postprocessor");
const path = require("path");

/** @type {import('next-i18next').UserConfig} */
module.exports = {
  i18n: {
    defaultLocale: "cs",
    locales: ["cs", "en", "de"],
    use: [intervalPlural],
    serializeConfig: false,
    localePath: path.resolve("./public/locales"),
    localeDetection: false,
    reloadOnPrerender: process.env.NODE_ENV === "development",
  },
};
