export const BACKEND_API_URL = process.env.NEXT_PUBLIC_BACKEND_API_URL || "https://api.myorea-dev.qestapp.net";
export const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

export const PLACEHOLDER_IMAGE = "/assets/images/empty_state_picture.webp";
export const isProductionBuild = process.env.NODE_ENV === "production";
export const isServer = typeof window === "undefined";
export const isProduction = process.env.NEXT_PUBLIC_APP_ENV === "production";

export const DEFAULT_CURRENCY = "CZK";

export const PRIORITIZED_COUNTRIES_ORDER = ["CZ", "SK", "DE", "PL"];
