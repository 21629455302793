import { generateServerClientUsingReqRes } from "@aws-amplify/adapter-nextjs/api";
import { Schema } from "amplify/data/resource";
import { createServerRunner } from "@aws-amplify/adapter-nextjs";
import outputs from "../../../amplify_outputs.json";

export const reqResBasedClient = generateServerClientUsingReqRes<Schema>({
  config: outputs,
});

export const { runWithAmplifyServerContext } = createServerRunner({
  config: outputs,
});
